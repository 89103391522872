/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'send': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6.401 8.57l5.85-5.85-9.75 3.9 3.9 1.95zm6.879-4.822l-5.85 5.85L9.38 13.5l3.9-9.75zM.402 7.197a.727.727 0 01.055-1.326L15.001.054a.727.727 0 01.945.945L10.13 15.543a.727.727 0 01-1.326.055l-2.8-5.6L.402 7.196z" _fill="#07F"/>'
  }
})
